export const whatLinks = [
  {
    path: "/news/",
    text: "News",
  },
  {
    path: "/projects/",
    text: "Projects",
  },
  {
    path: "/international-tours/",
    text: "Recent Tours",
  },
  {
    path: "/sponsorships/",
    text: "Sponsorships",
  },
]

export const artistLinks = [
  {
    path: "/artists/",
    text: "All Artists",
  },
  {
    path: "/soloists/",
    text: "Soloists",
  },
  {
    path: "/composers/",
    text: "Composers",
  },
  {
    path: "/conductors/",
    text: "Conductors",
  },
  {
    path: "/orchestras/",
    text: "Orchestras",
  },
  {
    path: "/ensembles/",
    text: "Chamber Music",
  },
]

export const whoLinks = [
  {
    path: "/about/",
    text: "About",
  },
  {
    path: "/team/",
    text: "Team",
  },
  {
    path: "/work-with-us/",
    text: "Careers",
  },
  {
    path: "/contact/",
    text: "Contact",
  },
]
